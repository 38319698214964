<template>
    <div class="identityContent">
        <div class="flexRow" style="justify-content: space-between;">
            <div style="width: 40%;">
                <label>Logo</label>
                <EditableImage :editable="true" class="logo" @change="changeImage($event,'logo')"
                    src_default="default_logo" :src_img="local_current_identity.ident_logo" />
            </div>
            <div style="width: 40%;">
                <label>Watermark</label>
                <EditableImage :editable="true" class="logo" @change="changeImage($event,'watermark')"
                    src_default="default_watermark" :src_img="local_current_identity.ident_watermark" />
            </div>

        </div>
        <div class="flexRow" style="justify-content: space-between;">
            <div style="width: 40%;">
                <label>{{ $t("IdentityChoice.font") }}</label>
                <v-select class="overwriteSelectStyle fontSelection" :options="fonts"
                    :reduce="font => font.value" v-model="font_selected" />
            </div>
            <div style="width: 40%;">
                <label>Baseline</label>
                <input placeholder="Ma baseline" type="text" ref="baseline" name="baseline" @input="baselineChanged"
                    data-dashlane="nodashlane" style="width: 100%;" />
            </div>
        </div>
        <div class="">
            <label>{{ $t("IdentityChoice.colors") }}</label>
            <div class="flexRow colors center">
                <div class="color">
                    <label>{{ $t("IdentityChoice.main") }}</label>
                    <ColorPicker class="colorPicker" v-model="local_current_identity.ident_colors[0]"
                        defaultValue="#00ADB5" />
                    <input type="text" :value="local_current_identity.ident_colors[0]" @change="changeColor($event, 0)"
                        placeholder="#00ADB5" data-dashlane="nodashlane" />
                </div>
                <div class="color">
                    <label>{{ $t("IdentityChoice.secondary") }}</label>
                    <ColorPicker class="colorPicker" v-model="local_current_identity.ident_colors[1]"
                        defaultValue="#3d545c" />
                    <input type="text" :value="local_current_identity.ident_colors[1]" @change="changeColor($event, 1)"
                        placeholder="#3d545c" data-dashlane="nodashlane" />
                </div>
                <div class="color">
                    <label>{{ $t("IdentityChoice.text") }}</label>
                    <ColorPicker class="colorPicker" v-model="local_current_identity.ident_colors[2]"
                        defaultValue="#FFFFFF" />
                    <input type="text" :value="local_current_identity.ident_colors[2]" @change="changeColor($event, 2)"
                        placeholder="#FFFFFF" data-dashlane="nodashlane" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//LIBS
import getColors from "get-image-colors";
// COMPONENTS
import EditableImage from "../../../../Object/EditableImage";

import axios from 'axios'

export default {
    name: "IdentityContent",
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            local_current_identity: this.modelValue,
            font_selector_placeholder: "Police d'écriture",
            animation_default_feature_value: {
                colors: [],
                font: {},
                logo: "",
            },
            fonts: []

        };
    },
    components: {
        EditableImage,
    },
    mounted() {
        //Setting up a default font
        this.$refs.baseline.value = this.local_current_identity.ident_baseline;
        //Trigger function to update default animation feature
        this.current_identity_changed(false);
        axios.get('/animations/font?charsData=false')
        .then((res)=>{
            //console.log(res.data)
            let array = []
            res.data.forEach(element => {
                array.push({ label : element.font_fName.value, value : element._id })
            });
            this.fonts = array
        })
      
    },
    computed: {

        font_selected: {
            get() {
                //RETREIEVE FONT WITH ID
                let index = this.fonts.findIndex(f=>f.value == this.local_current_identity.ident_font)
                if(index != -1){
                    return {
                        label: this.fonts[index].label,
                        value: this.fonts[index].value
                    }
                }
                else {
                    return {
                        label: "",
                        value: null
                    }
                }
            },
            set(value) {
                this.local_current_identity.ident_font = value;
                this.current_identity_changed();
            }
        }
    },
    methods: {

        changeColor(event, index) {
            let value = event.target.value
            if (value.charAt(0) != "#"){
                value = "#"+value
            }
            this.local_current_identity.ident_colors[index] = value;
            this.$emit('update:modelValue', this.local_current_identity)
        },
        baselineChanged(evt) {

            if (this.local_current_identity.ident_baseline != evt.target.value) {
                this.local_current_identity.ident_baseline = evt.target.value;
                this.$emit('update:modelValue', this.local_current_identity)
            }

        },
        changeImage(event, type) {
            if (type == 'logo') {
                this.local_current_identity.uploaded_logo_file = event.target.file;
                this.local_current_identity.media = event.target.url;

                //If no colors defin, guess it
                //if (this.current_identity.ident_colors.length == 0){
                getColors(event.target.url, event.target.file.type)
                    .then((colors) => {
                        colors = colors.map((color) => color.hex());
                        this.local_current_identity.ident_colors = [
                            colors[0],
                            colors[1],
                            colors[2],
                        ];
                        this.current_identity_changed();
                        this.$emit('update:modelValue', this.local_current_identity)
                    })
                    .catch((e) => {
                        console.error("ERROR COLOR : ", e);
                    });
                //}
            }else if (type == 'watermark'){
                this.local_current_identity.uploaded_watermark_file = event.target.file;
            //    this.local_current_identity.media = event.target.url;

            }

        },

        current_identity_changed() {
            //DEFAULT ANIMATION VALUE
            //Colors
            this.animation_default_feature_value.colors =
                this.local_current_identity.ident_colors;

            if (this.local_current_identity.ident_logo_url) {
                this.animation_default_feature_value.logo =
                    this.local_current_identity.ident_logo_url;
            }

            //Search font name (For UI)
            if (this.local_current_identity.ident_font != null) {
                this.$emit('update:modelValue', this.local_current_identity)
            }
        },
    }
}
</script>
  
<style src="../style.css" scoped>

</style>


<style>
/* SELECT DROPDOWN */
.overwriteSelectStyle .vs__dropdown-toggle {
    background: white;
}

.overwriteSelectStyle {

    --vs-colors--lightest: var(--color1);
    --vs-colors--light: var(--color2);
    --vs-colors--dark: var(--color1) !important;
    --vs-colors--darkest: var(--color2) !important;

    /* Disabled State */
    --vs-state-disabled-bg: rgb(248, 248, 248);
    --vs-state-disabled-color: var(--vs-colors--light);
    --vs-state-disabled-controls-color: var(--vs-colors--light);
    --vs-state-disabled-cursor: not-allowed;

    --vs-search-input-color: var(--color2);
    --vs-state-disabled-bg: white;
    --vs-search-input-bg: white;
    --vs-dropdown-bg: white;
    --vs-border-radius: var(--border_radius);
    --vs-border-width: var(--border_weight);
    --vs-border-color: var(--color_border_input);

    --vs-font-size: 1em;

    /* Selected */
    --vs-selected-bg: var(--fond_color);
    --vs-selected-color: var(--color2);
    --vs-selected-border-color: var(--vs-border-color);
    --vs-selected-border-style: var(--vs-border-style);
    --vs-selected-border-width: var(--vs-border-width);


    --vs-dropdown-box-shadow: 0;

    /* DROPDOWN */
    --vs-dropdown-option--active-bg: var(--color1);
    --vs-dropdown-max-height: 200px;
    /*
  outline: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1em;
    color: var(--color2);
    font-family: inherit;
*/
}

.pickr {
    display: inline
}
</style>